import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live startHidden",
        "live": true,
        "startHidden": true
      }}>{`() => {
  const defaultSpriteState = {
    borderRadius: 0,
    width: 60,
    height: 60,
    color: "__primary",
    angle: 0
  };
  const [spriteState, setSpriteState] = React.useState(defaultSpriteState);
  const { borderRadius, width, height, color, angle } = spriteState;
  return (
    <>
      <Controller>
        {props => (
          <Timeline
            {...props}
            track={[
              {
                duration: 3000,
                state: {
                  angle: { to: 360 }
                }
              },
              {
                duration: 1000,
                state: {
                  width: { to: 200 },
                  height: { to: 200 },
                  borderRadius: { to: 100 }
                }
              },
              { duration: 500 },
              {
                duration: 1000,
                state: {
                  height: { to: 20 }
                }
              },
              {
                duration: 500,
                state: {
                  width: { to: 500 }
                }
              },
              {
                duration: 1000,
                state: {
                  height: { to: 200 },
                  color: { to: "__tertiary" },
                  borderRadius: { to: 5 }
                }
              }
            ]}
            defaultState={defaultSpriteState}
            onUpdate={({ state }) => setSpriteState(state)}
          />
        )}
      </Controller>
      <div style={{ height: 250 }}>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <div
            style={{
              width: width,
              height: height,
              transform: \`rotate(\${angle}deg)\`,
              backgroundColor: color,
              borderRadius: borderRadius
            }}
          />
        </div>
      </div>
    </>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      