import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live renderOnly",
        "live": true,
        "renderOnly": true
      }}>{`() => {
  const { multi, layerResolvers } = TrackUtils;
  const { d3Ease } = Lib;

  const defaultState = { width1: 30, width2: 30, morph1: 1, morph2: 1 };
  const track = [
    { duration: 500 },
    multi([
      multi([
        {
          duration: 100,
          state: {
            morph1: { to: 0 }
          }
        },
        {
          duration: 1000,
          state: {
            width1: { to: 50 }
          }
        }
      ]),
      multi([
        {
          duration: 100,
          state: {
            morph2: { to: 0 }
          }
        },
        {
          duration: 1000,
          state: {
            width2: { to: 50 }
          },
          easing: d3Ease.easeElastic
        }
      ])
    ])
  ];

  const [animState, setAnimState] = React.useState(defaultState);
  const wrapperStyle = {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 10
  };

  return (
    <>
      <Controller>
        {props => (
          <Timeline
            {...props}
            endBehavior="loop"
            defaultState={defaultState}
            track={track}
            onUpdate={({ state }) => setAnimState(state)}
          />
        )}
      </Controller>
      <div
        style={{
          height: 150,
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center"
        }}
      >
        <div style={wrapperStyle}>
          <div
            style={{
              position: "relative",
              borderRadius: animState.width1 * animState.morph1 * 0.5,
              width: animState.width1,
              height: animState.width1,
              backgroundColor: "__primary"
            }}
          />
          <p>Current</p>
        </div>
        <div style={wrapperStyle}>
          <div
            style={{
              position: "relative",
              borderRadius: animState.width2 * animState.morph2 * 0.5,
              width: animState.width2,
              height: animState.width2,
              backgroundColor: "__primary"
            }}
          />
          <p>Goal</p>
        </div>
      </div>
    </>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      